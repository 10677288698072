export const myOrdersData = [
  {
    column1: 'NSE',
    column2: 'RELINFRA EQ',
    column3:33.75,
    column4:1.20
  },
  {
    column1: 'NSE',
    column2: 'RELINFRA EQ',
    column3:33.75,
    column4:1.20
  },
  {
    column1: 'NSE',
    column2: 'RELINFRA EQ',
    column3:33.75,
    column4:1.20
  },
  {
    column1: 'NSE',
    column2: 'RELINFRA EQ',
    column3:33.75,
    column4:1.20
  },
  {
    column1: 'NSE',
    column2: 'RELINFRA EQ',
    column3:33.75,
    column4:1.20
  },
  {
    column1: 'NSE',
    column2: 'RELINFRA EQ',
    column3:33.75,
    column4:1.20
  },
  {
    column1: 'NSE',
    column2: 'RELINFRA EQ',
    column3:33.75,
    column4:1.20
  },
  {
    column1: 'NSE',
    column2: 'RELINFRA EQ',
    column3:33.75,
    column4:1.20
  },
  {
    column1: 'NSE',
    column2: 'RELINFRA EQ',
    column3:33.75,
    column4:1.20
  },
  {
    column1: 'NSE',
    column2: 'RELINFRA EQ',
    column3:33.75,
    column4:1.20
  },
  {
    column1: 'NSE',
    column2: 'RELINFRA EQ',
    column3:33.75,
    column4:1.20
  },
  {
    column1: 'NSE',
    column2: 'RELINFRA EQ',
    column3:33.75,
    column4:1.20
  },
  {
    column1: 'NSE',
    column2: 'RELINFRA EQ',
    column3:33.75,
    column4:1.20
  },
 { column1: 'NSE',
  column2: 'RELINFRA EQ',
  column3:33.75,
  column4:1.20
},
{
  column1: 'NSE',
  column2: 'RELINFRA EQ',
  column3:33.75,
  column4:1.20
},
{
  column1: 'NSE',
  column2: 'RELINFRA EQ',
  column3:33.75,
  column4:1.20
},
{
  column1: 'NSE',
  column2: 'RELINFRA EQ',
  column3:33.75,
  column4:1.20
},
// {
//   column1: 'NSE',
//   column2: 'RELINFRA EQ',
//   column3:33.75,
//   column4:1.20
// },
// {
//   column1: 'NSE',
//   column2: 'RELINFRA EQ',
//   column3:33.75,
//   column4:1.20
// },
  ];