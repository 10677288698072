<div class="dynamic-header-geojit-dashboard-wrapper">
    <igx-grid #grid2 [data]="data" [height]="height" [rowHeight]="21" class="dynamic-grid-wrap"
        style="--igx-grid-cell-active-border-color:transparent">
        <igx-column *ngFor="let c of columns" [sortable]="c.sortData" [filterable]="filterData" [width]="c.width"
            [field]="c.field" [dataType]="c.type" style="background: aquamarine;">

            <ng-template igxHeader>
                <span class="dynamic-header-geojit-dashboard">
                    {{c.header}}
                </span>
            </ng-template>
            <ng-template igxCell let-cell="cell">
                <div class="dynamic-cell-geojit-dashboard" *ngIf="!c.image" [ngStyle]="{'font-weight':c.fontWeight}">
                    <span *ngIf="c.color&&cell.value>0">
                        <span style="color:#19C17D">{{ cell.value }}</span>
                    </span>
                    <span *ngIf="c.color&&cell.value<0">
                        <span style="color:#FF3319">{{ cell.value }}</span>
                    </span>
                    <span *ngIf="c.background&&cell.value<571.69">
                        <span style="background-color:#FF3319;color: white;">{{ cell.value }}</span>
                    </span>
                    <span *ngIf="c.background&&cell.value>571.69">
                        <span style="background-color:#19C17D;color: white;">{{ cell.value }}</span>
                    </span>
                    <span *ngIf="c.background&&cell.value==571.69">
                        <span style="background-color:transparent">{{ cell.value }}</span>
                    </span>
                    <span *ngIf="!c.color&&!c.background">
                        {{ cell.value }}
                    </span>
                </div>
                <div class="dynamic-cell-geojit-dashboard" *ngIf="c.action" style="text-align: right;">
                    <img src="/assets/dashboard/trend-icon.svg" class="trend-icon" />
                </div>
                <div class="dynamic-cell-geojit-dashboard" *ngIf="c.image">
                    <img src="/assets/dashboard/arrow-top.svg" class="header-icon" />
                </div>
            </ng-template>
        </igx-column>
    </igx-grid>
</div>